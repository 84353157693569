import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import IndexPageComponent from "../components/IndexPage"

function IndexPage({ location, data }) {
  useEffect(() => {
    if (typeof window.twttr !== "undefined") {
      window.twttr.widgets.load()
    }
  }, [])
  return (
    <Layout location={location}>
      <SEO
        description="CEO of Coupa, Author, and Business advisor published Smarter Together: How communities are shaping the next revolution in business. Buy Today"
        keywords="Rob Bernshteyn, Smarter Together Book, Smarter Together: How Communities are Shaping the next Revolution in Business"
        url="https://robideas.com/"
        image="https://www.coupa.com/v2/assets/img/Rob_top_mobile.png"
      />
      <IndexPageComponent {...data} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
  heroBG: PropTypes.object,
  childImageSharp: PropTypes.object,
  fluid: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          markdownFile: { ne: "article-cards" }
          featured: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM-YYYY-DD")
            rawDate: date
            featured
            title
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    heroBG: file(relativePath: { eq: "hero-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robBG: file(relativePath: { eq: "Rob_top_crop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robMobile: file(relativePath: { eq: "Rob_top_mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robQuote1: file(relativePath: { eq: "Rob_quote_1.png" }) {
      ...fluidImage
    }
    robQuote2: file(relativePath: { eq: "robquote2.png" }) {
      ...fluidImage
    }
    cta1: file(relativePath: { eq: "Coupa_ST_3D_cover.png" }) {
      ...fluidImage
    }
    cta2: file(relativePath: { eq: "Coupa_VAAS_3D_spine.png" }) {
      ...fluidImage
    }
    textYaml(yamlPage: { eq: "index" }) {
      heroTitle
      heroBody
      quoteText
      quoteAuthor
      ctaTitle
      ctaSubTitle
      ctaCopy
      ctaButton
      cardText {
        cardQuote
        cardAuthorTitle
      }
      ctaTitle1
      ctaSubTitle1
      ctaCopy1
      ctaButton1
      cardText2 {
        cardQuote
        cardAuthorTitle
      }
      quoteText1
      quoteAuthor1
      logoGridHeading
      tweetTitle
      tweetSubTitle
      tweetCopy
      tweetSubHeading
      tweetButton
      tweetButton1
      tweetPlaceholder
      logoGrid {
        image {
          ...fluidImage
        }
        heading
        alt
        href
        button
        largeImage
      }
    }
  }
`
